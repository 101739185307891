
export const EMAIL_FIELD_ID = "username"
export const PASSWORD_FIELD_ID = "password"
export const NAME_FIELD_ID = "displayName"
export const FIRSTNAME_FIELD_ID = "firstname"
export const LASTNAME_FIELD_ID = "lastname"
export const PHONE_FIELD_ID = "mobile"
export const ROLE_FIELD_ID = "role"
export const ADDRESS_FIELD_ID = "address"
export const CITY_FIELD_ID = "city"
export const ZIP_FIELD_ID = "zipcode"
export const STATE_FIELD_ID = "state"
export const COUNTRY_FIELD_ID = "country"
export const COMPANY_FIELD_ID = "company"
export const USERNAME_FIELD_ID = "username"
export const FEDERAL_FIELD_ID = "federalId"
export const CEO_FIELD_ID = "ceoName"
export const DRIVERLISENCE_FIELD_ID = "driverLisence"
export const ADDRESSPROOF_FIELD_ID = "addressProof"
